@font-face {
    font-family: 'avara700';
    src: url('avara-bold-webfont.woff2') format('woff2'),
         url('avara-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'avara800';
    src: url('avara-black-webfont.woff2') format('woff2'),
         url('avara-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}